<template>
  <!-- <v-label class="text-body-2 font-weight-bold ml-1">{{ label }}</v-label> -->
  <v-autocomplete
    class="text-Field"
    density="compact"
    :rules="rules"
    :disabled="disabled"
    :items="items ? items : []"
    :label="label"
    :placeholder="label || placeholder"
    variant="outlined"
    :item-title="text"
    :item-value="value"
  >
  </v-autocomplete>
</template>

<script>
export default {
  props: ["items","rules","label","text","value","disabled",'placeholder'],
  data() {
    return {};
  },
};
</script>

