import ApiService from './ApiService';
const querystring = require('querystring');
import apiConfig from '@/api/config/config.js';
var CompanyService = {

    findAll: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "companies"
            $options.method = "get"
            $options.baseUrl = apiConfig.adminUrl
            if ($data) {
                $options.url = "companies?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    findUserCompany: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "getUserCompany"
            $options.method = "get"
            $options.baseUrl = apiConfig.adminUrl
            if ($data) {
                $options.url = "getUserCompany?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    findOne: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "companies/" + $data.id
            console.log($options.url)
            $options.method = "get"
            $options.baseUrl = apiConfig.adminUrl
            if (!$data) {
                $options.data = $data
            }
            ApiService.request($options)
                .then(response => {
                    // console.log('Gpt the data');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    update: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "companies/" + $data.id;
            $options.baseUrl = apiConfig.adminUrl
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },
    save: function ($data) {
        return new Promise(function (resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "companies" + $data.id;
            $options.baseUrl = apiConfig.adminUrl
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },

    create: function ($data) {
        return new Promise(function (resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "companies";
            $options.method = "post"
            $options.baseUrl = apiConfig.adminUrl
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },
    createRoute: function ($data) {
        return new Promise(function (resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "createDomain";
            $options.method = "post"
            $options.baseUrl = apiConfig.adminUrl
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },
    checkSubDomain: function ($data) {
        return new Promise(function (resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "checkDomain";
            $options.method = "get"
            $options.baseUrl = apiConfig.adminUrl
            if ($data) {
                $options.url = "checkDomain?" + querystring.stringify($data)
                console.log($options.url)
            }
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },
    delete: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "companies/" + $data.id;
            $options.baseUrl = apiConfig.adminUrl
            $options.method = "delete"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    }
};
export default CompanyService;