<template>
   <!-- <v-label class="text-body-2 font-weight-bold ml-1" v-if="label">{{ label }}</v-label> -->
   <v-text-field density="compact"   variant="outlined" class="text-Field"  :rules="rules" :label="label"
      :disabled="disabled" :placeholder="label || placeholder" :append-inner-icon="icon ? icon : ''"
      :single-line="singleLine ? singleLine : false" :hide-details="hideDetails ? hideDetails : false">
   </v-text-field>
</template>
<script>
export default {
   props: ['label', 'icon', 'rules', 'placeholder', 'disabled', 'value',"singleLine","hideDetails"],
   data() {
      return {
         $value: ''
      }
   },
   computed: {
      textData: {
         get() {
            return this.value;
         },
         set($val) {
            this.$value = $val;
            this.$emit("changed", $val);
         },
      },
   },
   methods:{
      removeWhiteSpace(text) {
  return text.replace(/[\s/]/g, '');
}
   }
}
</script>  
<style lang="scss">
.text-Field{
  .v-field {
    background-color: #ececec !important;
    border-radius: 8px !important;
    border: 1px solid #e3e3e3;
    font-size: 13px;
    font-weight: 500;
  }
  .v-field:focus-within {
    background-color: #ffffff !important;
    border-radius: 8px !important;
    border: 0px solid #dadada;
  }
  .v-field__outline {
    --v-field-border-width: 0px !important;
 }
 .v-field--variant-outlined.v-field--focused .v-field__outline {
  --v-field-border-width: 0px !important;
  border: 2px solid #3C5AAA !important;
}
.v-field__input{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100% !important;
  opacity: inherit !important;
  padding-top: 10px;
}
.v-field__input input {
  letter-spacing: inherit;
  width: 100% !important;
  opacity: inherit;
  align-self: center !important;
}
.v-field--error:not(.v-field--disabled) .v-field__outline{
  border: 2px solid #c20020 !important;
}
.v-field--disabled {
 opacity: 0.6 !important;
}
.v-field--variant-outlined .v-label.v-field-label--floating {
  transform: translateY(-120%);
  transform-origin: center;
  margin: 0 4px;
  visibility: inherit !important;
  font-size: 13px;
  font-weight: 600;
  position: relative;
  right: 11px;
  width: 100% !important;
  opacity: inherit;
  top: 0px !important;
}
.v-field--error:not(.v-field--disabled) .v-label.v-field-label {
  color:#000 !important;
}
.v-label.v-field-label {
  visibility: hidden !important;
}
.v-list-item-title {
  font-size: 10px !important;
}
.v-field__prepend-inner, .v-field__append-inner, .v-field__clearable {
  align-items: flex-start;
  display: flex;
  padding-top: var(--v-input-padding-top, 10px);
  flex-flow: wrap;
  max-width: 100%;
}
.v-field.v-field--prepended {
  --v-field-padding-start: 6px;
  display: flex;
  flex-flow: wrap;
}
}
</style>