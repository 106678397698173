<template>
  <OnBoarding />
</template>

<script>
import { defineComponent } from 'vue';
import OnBoarding from '@/OnBoarding/index.vue'
export default defineComponent({
  name: 'HomeView',

  components: {
    OnBoarding,
  },
});
</script>
