<template>
  <div class="slide">
    <v-container fluid justify-center class="pa-15">
      <v-card dense flat min-height="90%" max-height="90%" width="92%" class="pa-5 rounded-lg white--bg slider-card">
        <v-card-title v-if="step != 4" class="text-h6 font-weight-black text-left">
          <v-row no-gutters>
            <v-col cols="8">
              Help us to Identify you!
            </v-col>
            <v-col cols="4" class="text-right">
              <img style="justify-content:end;width:128px; text-align:right;" :src="require('@/assets/Xtend 3.0.svg')" />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-2" style="height: 80%">
          <v-row no-gutters dense>
            <v-col cols="12">
              <StepperControl :headerStep="stepper" :moveStepper="stepmove" @stepper-selected="(step) => {
                this.stepper = step;
              }
                " />
            </v-col>
            <v-col cols="12" class="pa-5">
              <v-row class="nextButton">
                <v-col class="pa-0">
                  <v-btn density="comfortable" style="color: #ffffff;" v-if="stepper != 1"
                    @click="stepper >= 2 ? stepper-- : stepper" class="save">
                    Previous
                  </v-btn>
                  <!-- <v-icon class="leftIcon" v-if="stepper != 1" @click="stepper >= 2 ? stepper-- : stepper" size="30">mdi-chevron-left-circle-outline</v-icon> -->
                </v-col>
                <v-col align="end" class="pa-0">
                  <!-- <v-icon  class="rightIcon" @click="save()" v-if="stepper != 5" size="30">mdi-chevron-right-circle-outline</v-icon> -->
                  <v-btn @click="save()" :loading="nextButton"  style="color: #ffffff;" density="comfortable" class="save">
                    {{ stepper != 5 ? 'Next' : 'Submit' }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <OnBoard1 @save="submit($event)" :validation="validation1" v-if="stepper == 1"></OnBoard1>
            </v-col>
            <v-col cols="12">
              <OnBoard2 @save="submit($event)" :validation="validation1" v-if="stepper == 2"></OnBoard2>
            </v-col>
            <v-col cols="12">
              <OnBoard3 @save="submit($event)" :validation="validation1" v-if="stepper == 3"></OnBoard3>
            </v-col>
            <v-col cols="12">
              <OnBoard4 @save="submit($event)" :validation="validation1" v-if="stepper == 4"></OnBoard4>
            </v-col>
            <v-col cols="12">
              <OnBoard5 @save="submit($event)" :validation="validation1" v-if="stepper == 5"></OnBoard5>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- <v-card-actions style="justify-content: end; width: 100%; flex: end" class="text-xs-right card-slider-footer">
          <v-btn v-if="stepper != 1" @click="stepper >= 2 ? stepper-- : stepper" class="save" style="color: #ffff">
            Previous
          </v-btn>
          <v-btn @click="save()" v-if="stepper != 5" class="save" style="color: #ffff">
            Next
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-container>
  </div>
</template>
  
<script>
import StepperControl from "./StepperControl.vue";
import OnBoard1 from "./OnBoard1.vue";
import OnBoard2 from "./OnBoard2.vue";
import OnBoard3 from "./OnBoard3.vue";
import OnBoard4 from "./OnBoard4.vue";
import OnBoard5 from "./OnBoard5.vue";
export default {
  components: {
    StepperControl,
    OnBoard1,
    OnBoard2,
    OnBoard3,
    OnBoard4,
    OnBoard5,
  },
  data() {
    return {
      step: 0,
      stepper: 1,
      steps: 0,
      userData: {},
      erpValue: "",
      stepmove: false,
      reloadData: false,
      nextButton: false,
      validation1: false,
    };
  },
  created() {
    let token = this.$route.query.validToken
    localStorage.setItem('_TPMIT_', token)
  },
  methods: {
    checkMarketplace() {
      this.step = 3;
    },
    reloadBoard() {
      this.reloadData = !this.reloadData;
      this.step = 0;
    },
    checkCompany() {
      this.step = 4;
    },
    checkErpType($event) {
      if ($event.value) {
        this.step = 3;
      } else {
        this.step = 2;
        this.erpValue = $event.data.data_value;
      }
    },
    submit(value) {
      this.nextButton = false
      if (value) {
        if (this.stepper <= 4) {
          this.stepper++;
        } else {
          this.stepper = this.stepper;
        }
      }
    },
    save() {
      this.nextButton = true
      this.validation1 = !this.validation1;
    },
    checkValue($event) {
      if ($event.value == "erp") {
        this.step = 1;
      } else if ($event.value == "marketplace") {
        this.step = 3;
      } else {
        this.step = 4;
      }
    },
  },
};
</script>
  
<style lang="scss">
.save {
  background-color: #3c5aaa;
  color: #ffffff;
  width: 90px;
  font-size: 12px;
}

.active-slide {
  background: #d9d9d9;
  padding: 9px;
  border-radius: 100%;
}

.active {
  background: #43e971;
}

.slide {
  background: #3c5aaa;
  min-height: 100vh;
  min-width: 100vw;
  position: relative;
}

.slider-card {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  overflow: auto;
}

.nextButton {
  position: relative;
  top: 10px;
}

.card-slider-footer {
  background-color: #ffffff;
  border-top: 2px solid #adadad;
  width: 70%;
  position: fixed;
  top: 93%;
  z-index: 4;
  right: 2%;
}
</style>