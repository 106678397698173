<template>
  <v-row justify="center" class="mt-5">
    <v-col cols="12" class="font-weight-black text-center" style="font-size:25px;">
      Your Company Information
    </v-col>
  </v-row>
  <v-form ref="form">
    <v-row no-gutters dense class="pa-3 mt-5" justify="center">
      <v-col cols="4" class="mr-3 mt-4">
        <InputBox :label="'Company Name'" :rules="nameRules" v-model="formData.name" />
      </v-col>
      <v-col cols="4" class="mt-4">
        <InputBox :label="'Site Name'" @change="checkDomain(formData.portal_id)" :rules="portalRules"
          v-model="formData.portal_id" />
        <span style="color: red;" v-if="showDomainError">Already Exist</span>
      </v-col>
      <v-col cols="4" class="mr-3 mt-6">
        <InputBox :label="'City'" :rules="cityRules" v-model="formData.city" />
      </v-col>
      <v-col cols="4" class="mt-6">
        <InputBox :label="'Zip'" :rules="zipRules" @keypress="keyPressNumbers($event)" v-model="formData.zip_code" />
      </v-col>
      <v-col cols="4" class="mr-3 mt-6">
        <AutoComplete :label="'Country'" :rules="countryRules" v-model="formData.country"
          @update:modelValue="changeCountry($event)" :items="countries" text="name" value="id" />
      </v-col>
      <v-col cols="4" class="mt-6">
        <AutoComplete :label="'State'" :rules="stateRules" v-model="formData.state" :items="states" text="name"
          value="id" />
      </v-col>
    </v-row>
  </v-form>
  <v-row justify="center">
    <v-col cols="6" class="text-center">Explore the endless possibilities and deep drive into the world of ecommerce with
      <span style="font-size: 16px;color: #3C5AAA;">Xtend</span> as your partner</v-col>
  </v-row>
  <v-row justify="center">
  </v-row>
</template>
  
<script>
//import InputBox from 'frontendcomponent/src/components/InputBox.vue'
//import AutoComplete from 'frontendcomponent/src/components/AutoComplete.vue'
import InputBox from '@/components/InputBox.vue'
import AutoComplete from '@/components/AutoComplete.vue'
import Country from '../api/service/Countries.json'
import State from '../api/service/States.json'
import CompanyService from '@/api/service/CompanyService'
export default {
  props: ['reload', 'validation'],
  components: {
    InputBox,
    AutoComplete
  },
  data() {
    return {
      host: window.location.host,
      showDomainError: false,
      parts: window.location.host.split('.'),
      formData: {
        name: null,
        city: null,
        portal_id: null,
        zip_code: null,
        country: null,
        state: null,
        id: null,
        code: null,
      },
      countries: Country,
      states: [],
      nameRules: [(value) => (!!value) || 'Company Name Is Required'],
      portalRules: [(value) => (!!value) || 'Site Name Is Required'],
      cityRules: [(value) => (!!value) || 'City Is Required'],
      zipRules: [(value) => (!!value) || 'Zip Is Required'],
      stateRules: [(value) => (!!value) || 'State Is Required'],
      countryRules: [(value) => (!!value) || 'Country Is Required'],
    };
  },
  watch: {
    reload() {
      this.formData = {
        id: null,
        name: null,
        city: null,
        portal_id: null,
        zip_code: null,
        country: null,
        state: null,
        id: null,
        code: null,
      }
    },
    validation() {
      this.save()
    }
  },
  async created() {
    let userId = this.$route.query.userId
    let res = await CompanyService.findUserCompany({ user_id: userId })
    let company = localStorage.getItem("companyData")
    company = company ? JSON.parse(company) : ''
    if (company) {
      this.formData = company;
    }
    else {
      this.formData = res && res.length > 0 ? res[0] : {
        id: null,
        name: null,
        city: null,
        portal_id: null,
        zip_code: null,
        country: null,
        state: null,
        id: null,
        code: null,
      }
    }
  },
  methods: {
    changeCountry($event) {
      if ($event) {
        this.formData.state = null
        this.states = State[$event];
      }
    },
    async checkDomain(value) {
      await CompanyService.checkSubDomain({ subDomain: value })
        .then(res => {
          //console.log(res, 'alertcheckkkkkkkkkkkk')
          if (res && res.data && res.data.data) {
            this.showDomainError = true
          }
          else {
            this.showDomainError = false
          }
        })
    },
    keyPressNumbers(event) {
      var charCode = (event.which) ? event.which : event.keyCode;
      // Only Numbers 0-9
      if ((charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
      } else {
        return true;
      }
    },
    reloadBoard() {
      localStorage.removeItem('ERPData')
      localStorage.removeItem('MarketPlaces')
      this.$emit('reload')
    },
    async save() {
      let validation = await this.$refs.form.validate();
      await this.checkDomain(this.formData.portal_id)
      if (validation.valid && !this.showDomainError) {
        localStorage.setItem('companyData', JSON.stringify(this.formData))
        this.$emit('save', true)
      } else {
        this.$emit('save', false)
      }
    }
  }
}
</script>
  
<style scoped>
.save {
  background-color: #3C5AAA;
  color: #fff;
  width: 90px;
}
</style>