<template>
     <v-snackbar style="margin-top: 80px" v-model="snackbar" :timeout="1000" :top="true" :color="color" location="top">
        {{ msg }}
    </v-snackbar>
    <v-row justify="center" class="mt-5">
        <v-col cols="12" class="font-weight-black text-center" style="font-size:25px;">
            How do you want Integrate with us?
        </v-col>
        <v-col cols="8" class="font-weight-black text-center" style="font-size:25px;">
            <CardSelection :menuOptions="menuOptions" @cardSelected="cardSelected" />

        </v-col>
    </v-row>
</template>
<script>
import Stepper5 from '@/api/service/stepper5.json'
import CardSelection from 'frontendcomponent/src/components/CardSelection/index.vue'
import MwsHeaderService from '@/api/service/MwsHeaderService'
import CompanyService from '@/api/service/CompanyService'
import UserService from '@/api/service/UserService'
import ErpProfileService from '@/api/service/ErpProfileService'
export default {
    components: {
        CardSelection
    },
    props: ['reload', 'validation'],
    data() {
        return {
            menuOptions: Stepper5,
            selectedData: '',
            msg: '',
            snackbar: false,
            color: ''
        }
    },
    watch: {
        reload() {
            this.items.forEach(f => {
                f.isSelect = false
            })
        },
        validation() {
            this.save()
        }
    },
    methods: {
        async cardSelected($selected) {
            this.selectedData = $selected
        },
        async save() {
            let data = {
                id: this.$route.query.userId,
                first_time_user: 1
            }
            await UserService.update(data)
            let $marketplace = JSON.parse(localStorage.getItem('marketPlaceData'))
            if ($marketplace && $marketplace.length > 0) {
                for (let i = 0; i < $marketplace.length; i++) {
                    let data = {
                        code: $marketplace[i].code,
                        name: $marketplace[i].title,
                        market_place: $marketplace[i].title
                    }
                    await MwsHeaderService.create(data);
                }
            }
            let $erp = JSON.parse(localStorage.getItem('erpData'))
            if ($erp && $erp.code && this.selectedData) {
                let config = {
                    type: this.selectedData
                }
                let erpData = {
                    erp: $erp.code,
                    configuration: JSON.stringify(config),
                    active: 1
                }

                await ErpProfileService.create(erpData)
            }
            let companyData = JSON.parse(localStorage.getItem('companyData'))
            await CompanyService.update(companyData)
            let routeData = {
                subDomain: companyData.portal_id
            }
            await CompanyService.createRoute(routeData)
                    .then(res => {
                        console.log(res)
                        let url = this.$route.query.redirectUrl.split("?")[0]
                        let $token = this.$route.query.token
                        localStorage.removeItem('companyData')
                        localStorage.removeItem('onBoard2Data')
                        localStorage.removeItem('_TPMIT_')
                        setTimeout(() => {
                            this.snackbar = true
                            this.msg = 'Saved Successfully'
                            this.color = '#3C5AAA'
                            window.location = url + "?loginToken=" + $token
                            this.$emit('save', false)
                        }, 10000)

                    }, error => {
                        console.log(error)
                        this.snackbar = true
                        this.color = 'error'
                        this.msg = 'Something Went Wrong'
                        this.$emit('save', false)
                    })
        },
        select(index) {
            this.items.forEach(element => {
                element.isSelect = false
            });
            this.items[index].isSelect = true
            this.$emit('changeSlide', this.items[index])
        }
    }
}
</script>
