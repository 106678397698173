import apiConfig from '../config/config.js';
import axios from 'axios'

var headers = {
    'Content-Type': 'application/json'
}
const publicPages = ["resetPassword", "validateTeam", "signup", "activate", "authenticate",
    "companySignup", "createUser", "validateEmail",
    "findAllUser", "checkUser", "authenticateFirebase", "authInfo", 'getInvitation', 'acceptInvitation',
    'joinTeam'
]
var ApiService = {
    refreshToken: async function ($url, $options) {
       
        var $tiburaUser = localStorage.getItem('_TPMIT_')
        let $a = { headers: {  "appKey": apiConfig.appKey, "refresh_token": $tiburaUser ? $tiburaUser : "Abctoken" } }
        return await axios.get($url, $a)
            .then(async response => {
                ///$tiburaUser.data.token=response.data.token
                if (response.data.token) {
                    localStorage.setItem('_TPMIT_', response.data.token)
                }
                $options.url = $options.url.replace(apiConfig.baseUrl + "/", '')
                return ApiService.request($options)
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });

    },
    request: async function ($options) {
        return new Promise(function (resolve, reject) {
        
            if (publicPages.indexOf($options.url) >= 0) {
                headers = {
                    'Content-Type': 'application/json',
                    'appkey': apiConfig.appKey,
                  
                }
            }
            // else if ($options.url.indexOf('resetPassword') >= 0 ||

            //     $options.url.indexOf('companySignup') >= 0 ||
            //     $options.url.indexOf('validateEmail') >= 0 ||
            //     $options.url.indexOf('authenticate') >= 0 ||

            //     $options.url.indexOf('validateTeam') >= 0 ||
            //     $options.url.indexOf('createUser') >= 0 ||
            //     $options.url.indexOf('findAllUser') >= 0 ||
            //     //$options.url.indexOf('password')>=0 ||
            //     $options.url.indexOf('authInfo') >= 0 ||
            //     $options.url.indexOf('getInvitation') >= 0 ||

            //     $options.url.indexOf('acceptInvitation') >= 0 ||
            //     $options.url.indexOf('joinTeam') >= 0 ||


            //     $options.url.indexOf('activate') >= 0 ||
            //     $options.url.indexOf('signup') >= 0 ||
            //     $options.url.indexOf('checkUser') >= 0) {

            //      headers = {
            //         'Content-Type': 'application/json',
            //         'appkey': apiConfig.appKey,
            //          requestid:$requestId
            //     }
            // } 
            else {

                var $token = (localStorage.getItem('_TPMIT_'))
                // console.log($tiburaUser)
                //var $token = $tiburaUser.data.token

                headers = {
                    'Content-Type': 'application/json',
                    'Authorization': $token,
                    'appkey': apiConfig.appKey,
               
                  


                }
            }
            $options.url = $options.baseUrl + "/" + ($options.path ? $options.path : $options.url);
            if ($options.method.toLowerCase() == 'post') {
                let $h
                if ($options.responseType) {
                    $h = { responseType: "blob", headers: headers }
                } else {
                    $h = { headers: headers }
                }
                axios.post($options.url, $options.data, $h)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        console.log(error);
                        if (error.response.status == 401) {
                            resolve(ApiService.refreshToken($options.url, $options))
                        } else {
                            reject(error)
                        }
                    });
            } else if ($options.method.toLowerCase() == 'delete') {
                axios.delete($options.url, { "headers": headers })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        console.log(error);
                        if (error.response.status == 401) {
                            resolve(ApiService.refreshToken($options.url, $options))
                        } else {
                            reject(error)
                        }
                    });
            } else if ($options.method.toLowerCase() == 'put') {
                axios.put($options.url, $options.data, { headers: headers })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        console.log(error);
                        if (error.response.status == 401) {
                            resolve(ApiService.refreshToken($options.url, $options))
                        } else {
                            reject(error)
                        }
                    });
            } else {
                var $h = {}
                if ($options.responseType) {
                    $h = { responseType: "blob", headers: headers }
                } else {
                    $h = { headers: headers }
                }
                axios.get($options.url, $h)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(async error => {
                        console.log(error);
                    });
            }


        });


    }
};
export default ApiService;