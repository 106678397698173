<template>
<ol class="stepper">
  <li :class="step==1 ? 'selected' : '' ">
    <v-avatar @click="slectedTepper(1)" style="cursor:pointer" :class="step>1 ? 'stepperavatar-completed' : 'stepperavatar'">1</v-avatar>
    Let us get Started
   </li>
   <li :class="step==2 ? 'selected' : '' ">
    <v-avatar @click="slectedTepper(2)"  style="cursor:pointer"  :class="step>2 ? 'stepperavatar-completed' : 'stepperavatar'">2</v-avatar>
    Tell your about yourself</li>
    <li :class="step==3 ? 'selected' : '' ">
    <v-avatar @click="slectedTepper(3)"  style="cursor:pointer"  :class="step>3 ? 'stepperavatar-completed' : 'stepperavatar'">3</v-avatar>
    Marketplaces you want to explore</li>
   <li :class="step==4 ? 'selected' : '' ">
    <v-avatar @click="slectedTepper(4)"  style="cursor:pointer"  :class="step>4 ? 'stepperavatar-completed' : 'stepperavatar'">4</v-avatar>
    ERP that you currently use</li>
    <li :class="step==5 ? 'selected' : '' ">
    <v-avatar @click="slectedTepper(5)"  style="cursor:pointer"  :class="step>4 ? 'stepperavatar-completed' : 'stepperavatar'">5</v-avatar>
    Integration with ERP
  </li>
</ol>
</template>
<script>
export default {
    props:["headerStep","moveStepper"],
    data() {
        return {
        step:1
        }
    },
    watch:{
      headerStep(){
        this.step = this.headerStep
      }
    },
    created(){
      this.step = this.headerStep
    },
    methods:{
      slectedTepper(value){
          if(this.step>value || this.moveStepper){
            this.step=value
            this.$emit('stepper-selected',value)
          }
      }
    }
}
</script>
<style scoped>
.stepperavatar {
  background-color:lightgrey !important;
  z-index:2;
}
.stepperavatar-completed {
  background-color:#1258AB !important;
  z-index:2;
  color:#ffffff;
}
.selected .stepperavatar {
  background-color:#1258AB !important;
  color:#ffffff;
  z-index:2;
}

ol.stepper {
  --default-b: lightgrey;
  --default-c: black;
  --active-b: #1258AB;
  --active-c: white;
  --circle: 3.0em; /* size of circle */
  --b: 2px; /* line thickness */
  
  display: flex;
  list-style: none;
  justify-content: space-between;
  background: 
    linear-gradient(var(--default-b) 0 0) no-repeat
    50% calc((var(--circle) - var(--b))/2)/100% var(--b);
  counter-reset: step;
  margin: 10px;
  padding: 0;
  font-size: 14px;
  counter-reset: step;
  overflow: hidden;
}
ol.stepper li {
  display: grid;
  place-items: center;
  gap: 5px;
  position: relative;
}
ol.stepper li::before {
  counter-increment: step;
  display: grid;
  place-content: center;
  aspect-ratio: 1;
  height: var(--circle);
  border: 2px solid #fff;
  box-sizing: border-box;
  background: var(--active-b);
  color: var(--active-c);
  border-radius: 50%;
  font-family: monospace;
  z-index: 1;
}
ol.stepper li.selected ~ li::before{
  background: var(--default-b);
  color: var(--default-c);
}
ol.stepper li.selected::after {
  content: "";
  position: absolute;
  height: var(--b);
  right: 100%;
  top: calc((var(--circle) - var(--b))/2);
  width: 100vw;
  background: var(--active-b);
}


@media (max-width: 600px) {
  ol.stepper {
   display: grid;
   gap: 20px;
  background: 
    linear-gradient(var(--default-b) 0 0) no-repeat
    calc((var(--circle) - var(--b))/2) 50%/ var(--b) 100%;
  }
  ol.stepper li {
    display: flex;
  }
  ol.stepper li.selected::after {
    content: "";
    position: absolute;
    width: var(--b);
    bottom: 100%;
    left: calc((var(--circle) - var(--b))/2);
    top: auto;
    right: auto;
    height: 100vw;
    background: var(--active-b);
  }
}


</style>